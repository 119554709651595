<template>
  <h1>Events For Good</h1>
  <div class="home">
    <EventCard v-for="event in events" :key="event.id" :event="event" />
  </div>
</template>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

<script>
// @ is an alias to /src
import EventCard from "@/components/EventCard.vue";
import EventService from "@/services/EventService.js";

export default {
  name: "EventList",
  components: {
    EventCard,
  },
  data() {
    return {
      events: null,
    };
  },
  async created() {
    try {
      const response = await EventService.getEvents();
      this.events = response.data;
    } catch (err) {
      console.log(err);
    }
    //axios.get('https://my-json-server.typicode.com/Code-Pop/Real-World_Vue-3/events')
    //.then(response => {
    //this.events = response.data
    //})
    //.catch(error => {
    //console.log(error)
    //})
  },
};
</script>
